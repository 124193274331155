.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #red;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.search-input .MuiOutlinedInput-root {
  /* background-color: #f8f8f8; */
  border-radius: 24px;
  border-color: #bdbdbd;
  transition: border-color 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.search-input .MuiOutlinedInput-root:focus-within {
  /* background-color: #fff; */
  border-color: #4285f4;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.search-input .MuiOutlinedInput-input {
  padding: 10px 17px;
  font-size: 14px;
}

.search-input .MuiOutlinedInput-adornedEnd .MuiIconButton-root {
  margin-right: -12px;
  color: #909090;
  transition: color 0.2s ease;
}

.search-input .MuiOutlinedInput-adornedEnd .MuiIconButton-root:hover {
  color: #606060;
}

.search-input .MuiSvgIcon-root {
  color: #606060;
}

.search-input .Mui-focused .MuiSvgIcon-root {
  color: #909090;
}

.search-input .MuiOutlinedInput-notchedOutline {
  border-color: #bdbdbd;
}

.search-input .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4285f4;
}

.search-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #bdbdbd;
}

/* .dark-mode-icon {
  transition: transform 0.3s ease;
}

.dark-mode-icon.active {
  transform: rotate(180deg);
} */