.tutorial-card {
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tutorial-card iframe {
    border-radius: 8px;
}

.tutorial-title {
    margin-top: 16px;
    padding-top: 0px;
    text-align: left;
    transition: color 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.tutorial-description {
    transition: color 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.tutorial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tutorial-card:hover .tutorial-title {
    color: #ff9933;
    transform: scale(1.05);
}

.tutorial-card:hover .tutorial-description {
    color: #646464;
    transform: scale(1.05);
}

.see-all-button {
    background-color: #1db954;
    color: #fff;
    margin-top: 16px;
}

.hide-button {
    background-color: #888;
    color: #fff;
    margin-top: 16px;
}
